<template>
  <v-form ref="form" @submit.prevent="validateBeforeSubmit">
    <v-col class="mt-6 pb-0" cols="12">
      <v-card>
        <v-card-actions>
          <v-icon @click="close">mdi-arrow-left-circle</v-icon>

          <v-spacer></v-spacer>

          <v-btn color="primary" type="submit">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="12" class="pt-0">
      <v-card>
        <v-card-title class="h4">Additional participant export data</v-card-title>
        <v-card-text>
          <v-col class="pr-4" cols="12" md="6">
            <v-text-field v-model="vm.field1" label="Field 1"></v-text-field>
          </v-col>
          <v-col class="pr-4" cols="12" md="6">
            <v-text-field v-model="vm.field2" label="Field 2"></v-text-field>
          </v-col>
          <v-col class="pr-4" cols="12" md="6">
            <v-text-field v-model="vm.field3" label="Field 3"></v-text-field>
          </v-col>
          <v-col class="pr-4" cols="12" md="6">
            <v-text-field v-model="vm.field4" label="Field 4"></v-text-field>
          </v-col>
          <v-col class="pr-4" cols="12" md="6">
            <v-text-field v-model="vm.field5" label="Field 5"></v-text-field>
          </v-col>
          <v-col class="pr-4" cols="12" md="6">
            <v-text-field v-model="vm.field6" label="Field 6"></v-text-field>
          </v-col>
          <v-col class="pr-4" cols="12" md="6">
            <v-text-field v-model="vm.field7" label="Field 7"></v-text-field>
          </v-col>
          <v-col class="pr-4" cols="12" md="6">
            <v-text-field v-model="vm.field8" label="Field 8"></v-text-field>
          </v-col>
          <v-col class="pr-4" cols="12" md="6">
            <v-text-field v-model="vm.field9" label="Field 9"></v-text-field>
          </v-col>
          <v-col class="pr-4" cols="12" md="6">
            <v-text-field v-model="vm.field10" label="Field 10"></v-text-field>
          </v-col>
        </v-card-text>
      </v-card>
    </v-col>
  </v-form>
</template>

<script>
import Vue from "vue";
import VeeValidate from "vee-validate";

Vue.use(VeeValidate);

export default {
  data: () => ({
    vm: {}
  }),
  mounted() {
    this.init();
  },
  computed: {
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentCase() {
      return this.$store.getters.currentCase;
    },
    user() {
      return this.$store.getters.user;
    }
  },
  methods: {
    init() {
      this.vm =
        this.$store.getters.currentCase == undefined ||
        this.$store.getters.currentCase.additionalData == undefined
          ? {}
          : this.$store.getters.currentCase.additionalData;
    },
    validateBeforeSubmit() {
      let me = this;

      me.$validator.validateAll().then(result => {
        if (result) {
          me.currentCase.additionalData = Object.assign({}, me.vm);
          me.$store.dispatch("setCurrentCase", me.currentCase);
          
              me.$store.dispatch("setToast", {
                message: "Additional data saved",
                color: "success"
              });
        } else {
          me.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },
    close() {
      this.$router.push(
        "/participant-management/current-participant/invoicing-billing"
      );
    }
  }
};
</script>
